import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BroadcastService } from './broadcast.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public ENV = environment;

  public readonly serviceURL = this.ENV.serviceURL;
  public readonly appName = this.ENV.appName;

  public urls = {
    getEnvInfoUrl : this.serviceURL + 'envinfo',
    getCategoriesUrl : this.serviceURL + 'categories',
    getStaticFormUrl: this.serviceURL + 'categoriesstandards/advanced',
    getRegionFormUrl: this.serviceURL + 'location/region',
    getCountryNStateFormUrl: this.serviceURL + 'location/country',
    getDyamicFieldsUrl: this.serviceURL + 'searchfields?stdProgramGrp=',

    formFieldDataUrl: './assets/data/formdata.json',
    fetchFormParamsUrl: './assets/data/advFilterForm.json',
    getDetailInfoUrl : './assets/data/detailInfo.json',
    getprodDetailsUrl : './assets/data/productDetailsData.json',
    //getViewModelData : this.serviceURL + 'products/search/models',
    getViewModelData : this.serviceURL + 'products/search/models',
    getSearchResultResponse : this.serviceURL + 'products/search', //'./assets/data/Sample/searchResultData.json' //
    getCompanySearchResult : this.serviceURL + 'company/search/products', //'./assets/data/company_search.json' //
   // getProductDetailInfo : this.serviceURL + 'products/search/info/',
    //getModelDetailsInfo : this.serviceURL + 'products/search/modelinfo/',
    getProductHeaderDetailInfo : this.serviceURL + 'products/info/search',
    getModelHeaderDetailsInfo : this.serviceURL + 'model/info/search',
    getProductModelSearchDetails : this.serviceURL + 'products/search/models',
    getModelSearchDetails : this.serviceURL + 'model/search',
    exportGridData : this.serviceURL + 'products/search/export',
    exportComapnyGridData : this.serviceURL + 'company/search/products/export',
    exportProductGridData : this.serviceURL + 'products/info/export',
    exportModelGridData : this.serviceURL + 'model/info/export',
    createShareableLink : this.serviceURL + 'sharable/savelinkdetail',
    getShareableLink : this.serviceURL + 'sharable/getlinkdetail?shareLinkDetailId',
    getSearchResult : this.serviceURL + 'products/searchstandardproducts',
    //getCategoryHeaderDetailInfo : this.serviceURL + 'category/info/',
    getCategoryHeaderDetailInfo : this.serviceURL +'/category/info/search',
    getCategoryModelSearchDetails : this.serviceURL + 'category/search/models',
    exportCategoryGridData : this.serviceURL + 'category/info/export',
  }

  constructor(private http: HttpClient,
    private _broadcast: BroadcastService) {
  }

  public getPlainHeader() {
    sessionStorage.setItem("serviceURL", this.ENV.serviceURL);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }
    return httpOptions;
  }
  public getaccessToken() {
    const tokens = (sessionStorage.getItem('accessToken')) ? sessionStorage.getItem('accessToken') : '';

    const httpOptionsAuth = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': String(tokens) }),
    }
    return httpOptionsAuth;
  }
  // This method is to get through http api request
  public get(qry: string, headerOptions: any): Observable<any> {
    return this.http.get(qry, headerOptions).pipe(catchError((err) => this.handleError(err)));
  }

  // This method is to post through http api request
  public post(qry: string, data: any, headerOptions: any) {
    if (qry != '') {
      return this.http.post(qry, data, headerOptions).pipe(catchError((err) => this.handleError(err)));
    }
    else {
      return throwError({ err: "Empty URl" })
    }
  }

  // This method is to update through http api request
  public put(qry: string, data: any, headerOptions: any) {
    return this.http.put(qry, data, headerOptions).pipe(catchError((err) => this.handleError(err)));
  }

  // This method is to delete through http api request
  public delete(qry: string, prm: any[], headerOptions: any) {
    return this.http.delete(qry, headerOptions).pipe(catchError((err) => this.handleError(err)));
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    if(error?.error?.httpCode == 500){     
      this._broadcast.showLoader(false);
      this._broadcast.setErrorMessage({ iShowError: true, message: error?.error?.message });
    }   
    // Return an observable with a user-facing error message.
    if (error.status == 0) {
      return throwError(() => new Error('API Request Failed'))
    } else {
      return throwError(() => new Error((error && error.error && (error.error.message || error.error.sqlErrMsg || error.error.msgvalue)) ? error.error.message || error.error.sqlErrMsg || error.error.msgvalue : 'API Request Failed'));
    }
  }

}
