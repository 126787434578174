export class AppConstants {
    public static SEARCH_NAMES = "Product / Brand / Model / SKU / Trade Name / Company";    
    public static COMPANIES = "Company";
    public static ADVANCED_SEARCH = "Advanced Search";
    public static EDIT_SEARCH = "Edit Search";
    public static CLEAR_FILTER = "Clear Filter";
    public static SEARCH = "Search";
    public static CATEGORIES = "Browse by Category";  
    public static PRODUCT = "Products";
    public static COMPANIESLBL = "Companies";
   // //Based on ticket :15570 - public static SEARCHFOR ="Search for"
    public static SEARCHFOR =""
    public static SHOW_RESULTS = "Show results by";
    public static WINDOW_TILE_END_USE ="End Use Code(s)";
    public static WINDOW_TILE_HIGH_PRESSURE ="Thermostatic Mixing Valve Application Guide";
    public static WINDOW_TILE_LOW_PRESSURE ="Thermostatic Mixing Valve Application Guide";
    public static WINDOW_TILE_WATER_CONT_TEMP ="Temperature";
    public static WINDOW_TILE_WATER_CONT_MATERIAL ="Material";
    public static WINDOW_TILE_NONFOOD_CATEGORY ="Category Codes";

    public static company = "Company";
    public static location = "Facility Location";
    public static category = "Category";
    public static subCategory = "Sub-category";
    public static facilityLocation = "Facility Location";
    public static regionORCountry = "";
    public static region = "Region";
    public static country = "Country";
    public static state = "State (US only)";
    public static standard = "Standard/Program";
    public static CATEGORY_DETAILS = "Category Details";

    public static APPLIED_FILTERS = "Applied Filters";
    public static RESET_FILTERS = "Reset Filters";


    public static PRODUCTS : string = "Product";
    public static END_USE = "End Use";
    public static TRADE_NAME : string = "Trade Name";
    public static COMPANY = "Company";
    public static PRODUCT_TYPE = "Product Type";
    public static MATERIAL_TYPE = "Material Type";
    public static STANDARD_PROGRAM = "Standard/Program";
    public static CONTACT = "Contact";
    public static VISIT_COMPANY = "Visit this company's website";
    public static MODEL = "Model/Size";
    public static PRODUCT_STANDARD = "Product Standard(s)";
    public static VIEW_LISTING_DETAILS = "View Listing Details";

    // public static RESULT_FOUND = " result(s) found for your search";
    public static RESULT_FOUND = " result(s) found";

    public static NO_DATA_FOUND_1 = "Sorry!";
    public static NO_DATA_FOUND_2 = "Unfortunately, we couldn't show results based on your search. Please try a different search.";
    public static No_DATA_FOUND_3 = "Data not available, please search by product.";
    public static NO_DATA_FOUND_DEFAULT = "Currently there are no search results available."

    public static VIEW_MORE = "View More";
    public static VIEW_LESS = "View Less";

    // disclaimer start
    public static FOOTER_DISCLAIMER_PARA_1 = "To see which products have had their NSF certification revoked for noncompliance, visit the ";
    public static FOOTER_DISCLAIMER_PARA_1_LINK = "Certification Enforcement Actions";
    public static FOOTER_DISCLAIMER_PARA_1_2 = " page.";

    public static FOOTER_DISCLAIMER_PARA_2 = "If you are interested in learning more about NSF certification and registration marks, please visit the ";
    public static FOOTER_DISCLAIMER_PARA_2_LINK = "NSF Mark";
    public static FOOTER_DISCLAIMER_PARA_2_2 = " page.";

    public static FOOTER_DISCLAIMER_PARA_3 = "If you have any questions about whether a product is NSF certified or registered or about how to interpret these listings, please contact the NSF Certification Records Department at 800-NSF-MARK (800 673 6275) or (+1) 734 769 8010 or ";
    public static FOOTER_DISCLAIMER_PARA_3_LINK_1 = "certrec@nsf.org";
    public static FOOTER_DISCLAIMER_PARA_3_2 = " You may also contact the NSF Consumer Hotline at +1 800 673 8010 or ";
    public static FOOTER_DISCLAIMER_PARA_3_LINK_2 = "info@nsf.org";

    public static FOOTER_DISCLAIMER_PARA_4 = "Listing Details current as of ";
    public static FOOTER_DISCLAIMER_PARA_4_2 = " at ";
    public static FOOTER_DISCLAIMER_PARA_4_3 = ". Eastern Time. ";

    public static FOOTER_DISCLAIMER_PARA_5 = "The NSF Product Listings show currently listed companies and products. Although every reasonable effort has been made to assure accuracy, omissions or errors may occur due to the complexity of these listings and their conversion for the internet. If you notice an omission or error, please report it to NSF.";

    public static HEADER_DISCLAIMER_PARA_1 = "These search results are current as of ";
    public static HEADER_DISCLAIMER_PARA_1_2 = " at ";
    public static HEADER_DISCLAIMER_PARA_1_3 = ". Eastern Time. ";

    public static HEADER_DISCLAIMER_PARA_2 = "Alert: NSF is concerned about fraudulent downloading and manipulation of website text. Always confirm this information by visiting ";
    public static HEADER_DISCLAIMER_PARA_2_LINK = "https://www.nsf.org/certified-products-systems";
    public static HEADER_WARNING_LBL = "Warning! ";
    public static HEADER_WARNING_DISCLAIMER = "This is a beta site shown for demonstration purposes. The information on this site does not reflect certification nor should it be used as evidence of certification.";

    public static LISTING_DETAILS_MSG = "The below search results do not represent complete NSF listings. For complete listings, refer to the full listing details page for each product.";

    // disclaimer end

    //Error Message
    public static ATLEAST_ONE_COLUMN_MSG = "Please enter at least one search term before clicking Search";
    public static PLEASE_CONTACT_ADMIN = "Search encountered an error. Please Contact Admin";
    public static NO_RECORDS_FOUND = "No Records Found!";
    public static EXCEEDED_MAXIMUM_RESULT =  "There is an issue while processing the request, please try again or contact administrator.";
    public static ERROR = "Error";
    public static SUCCESS = "Success";

    public static MODEL_DETAILS = "Model Details by Facility";
    public static PRODUCT_MODEL_DETAILS = "Product / Model Details by Facility";
    public static FILE_DOWNLOAD_SUCCESS = "File DOwnloaded Successfully";
    public static CATEGORY_DETAILS_HEADER = "Product Details by Facility";
    public static SHARE_PAGE = "Share this page";    
    public static SHARE_LINK_DATA = 'share-link-data';
    public static HAS_SHARE_LINK  = 'has-share-link';
    public static CLEAR_FORM  = 'clear-form';
    public static COMPANY_URL_LABEL = 'Visit this Company’s Website'
}

export const SEARCH_RESULTS = 'Search Results';
export const SEARCH_HOME = 'Search Home';
export const SEARCH_RESULT_FLAG = 'search-result-flag';
